<template>
  <div
    id="team-view"
  >

    <rc-overlay :show="loading">
      <b-row class="match-height">

        <b-col
          md="4"
          sm="12"
        >

          <b-row class="justify-content-center">
            <b-col
              cols="12"
            >

              <b-card
                text-variant="white"
                :bg-variant="statusColor"
              >
                <div class="font-large-2 text-center">
                  {{ team.teamName.name }}
                </div>
              </b-card>

            </b-col>

            <b-col
              cols="12"
            >

              <statistic-card-horizontal
                icon="ClockIcon"
                color="primary"
                :statistic="timeOfWork"
                :statistic-title="$t('team.view.time-of-work')"
              />

            </b-col>

            <b-col
              cols="12"
            >

              <b-card>

                <div class="text-xl text-center">
                  <span class="text-nowrap">
                    {{ team.teamStartedAt ? formatDate(team.teamStartedAt) : '?' }}
                  </span>
                  -
                  <span class="text-nowrap">
                    {{ team.teamEndedAt ? formatDate(team.teamEndedAt) : '?' }}
                  </span>

                  <div v-if="canIEndTeamWork">
                    <b-button
                      v-if="!team.teamEndedAt"
                      :disabled="loading"
                      variant="flat-danger"
                      @click="endTeamWork"
                    >
                      {{ $t('team.view.end-team-work') }}
                    </b-button>
                  </div>
                </div>

              </b-card>

            </b-col>

          </b-row>

        </b-col>

        <b-col
          md="4"
          sm="12"
        >
          <b-row class="justify-content-between h-100">
            <b-col cols="12">

              <b-card
                :title="$t('team.view.paramedic')"
                class="card-justify"
              >

                <div class="text-center mt-1">
                  <div class="font-medium-5">
                    {{ team.paramedicUser.surname }} {{ team.paramedicUser.name }}
                  </div>
                  <div>
                    {{ team.paramedicUser.position }}
                  </div>
                </div>

              </b-card>

            </b-col>

            <b-col cols="12">

              <b-card
                :title="$t('team.view.driver')"
                class="card-justify"
              >

                <div class="text-center mt-1">
                  <div class="font-medium-5">
                    {{ team.driverUser.surname }} {{ team.driverUser.name }}
                  </div>
                  <div>
                    {{ team.driverUser.position }}
                  </div>
                </div>

              </b-card>

            </b-col>

          </b-row>
        </b-col>

        <b-col
          md="4"
          sm="12"
        >
          <b-card :title="$t('team.view.vehicle.title')">

            <div>
              <dl class="row mb-0">
                <dt class="col-6">
                  {{ $t('team.view.vehicle.side-number') }}
                </dt>
                <dd class="col-6">
                  {{ team.vehicle.sideNumber }}
                </dd>
              </dl>
              <dl class="row mb-25">
                <dt class="col-6">
                  {{ $t('team.view.vehicle.registration-number') }}
                </dt>
                <dd class="col-6">
                  {{ team.vehicle.registrationNumber }}
                </dd>
              </dl>
              <dl class="row mb-1">
                <dt class="col-6">
                  {{ $t('team.view.checklist-confirmed-at') }}
                </dt>
                <dd class="col-6">
                  <span v-if="team.checklistConfirmedAt">
                    {{ transformDatetimeToHumanReadablePretty(team.checklistConfirmedAt) }}
                  </span>
                  <b-badge
                    v-else-if="!loading"
                    variant="light-warning"
                  >
                    {{ $t('team.view.checklist-not-confirmed') }}
                  </b-badge>
                </dd>
              </dl>

              <div v-if="team.checklistComment">
                <label class="font-weight-bold">
                  {{ $t('team.view.checklist-comment') }}
                </label>
                <div>
                  <i>{{ team.checklistComment }}</i>
                </div>
              </div>
            </div>

          </b-card>
        </b-col>

      </b-row>

      <b-card no-body>

        <h4 class="pt-2 pl-2 pb-1">
          {{ $t('team.view.orders-list') }}
        </h4>
        <b-table
          ref="refTeamOrdersTable"
          class="position-relative"
          :items="team.orders"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('team.view.table.no-orders') "
        >

          <!-- Column: fullNumber -->
          <template #cell(fullNumber)="data">

            <b-link
              v-if="hasAtLeastOneRole(me.roles, ['ROLE_DISPATCHER', 'ROLE_PARAMEDIC'])"
              :to="{ name: 'order-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullNumber }}
            </b-link>

            <span
              v-else
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullNumber }}
            </span>

          </template>

          <!-- Column: status -->
          <template #cell(status)="data">

            <div class="text-nowrap">
              <b-badge variant="light-primary">
                {{ $t('order.status.' + data.item.status) }}
              </b-badge>
            </div>

          </template>

          <!-- Column: transportAt -->
          <template #cell(transportAt)="data">

            <div class="text-nowrap">
              {{ formatDate(data.item.transportAt) }}
            </div>

          </template>

          <!-- Column: problems -->
          <template #cell(problems)="data">

            <div class="text-nowrap">
              <b-badge
                v-if="data.item.status === 'completed'"
                variant="danger"
              >
                {{ $t('team.view.no-documents') }}
              </b-badge>
            </div>

          </template>

        </b-table>

      </b-card>
    </rc-overlay>

  </div>
</template>

<script>
import { cloneNested, parseRequestError, transformDatetimeToHumanReadablePretty } from '@/helpers/helpers'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import guard from '@/guard'
import moment from 'moment'
import {
  BBadge,
  BButton,
  BCard,
  BCol, BLink,
  BRow, BTable,
} from 'bootstrap-vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BBadge,
    BLink,

    RcOverlay,
    StatisticCardHorizontal,
  },
  data() {
    return {
      loading: false,

      tableColumns: [
        {
          label: this.$t('team.view.table.full-number'),
          key: 'fullNumber',
          sortable: true,
        },
        {
          label: this.$t('team.view.table.status'),
          key: 'status',
          sortable: true,
        },
        {
          label: this.$t('team.view.table.transport-at'),
          key: 'transportAt',
          sortable: true,
        },
        {
          label: this.$t('team.view.table.problems'),
          key: 'problems',
        },
      ],
    }
  },
  computed: {
    team() {
      return cloneNested(this.$store.getters['team/getTeamData'])
    },
    statusColor() {
      if (this.team.teamEndedAt) {
        return 'secondary'
      } if (this.team.status) {
        return 'success'
      }
      return 'danger'
    },
    timeOfWork() {
      const minutes = moment(this.team.teamEndedAt)
        .diff(this.team.teamStartedAt ? this.team.teamStartedAt : moment(), 'minutes')

      return `${Math.floor(minutes / 60)} ${this.$t('shared.time-h')} ${Math.round(minutes % 60)} ${this.$t('shared.time-m')}`
    },
    me() {
      return this.$store.getters['auth/getUser']
    },
    canIEndTeamWork() {
      return this.me.id === this.team.paramedicUser.id
          || guard.hasAtLeastOneRole(this.me.roles, ['ROLE_DISPATCHER'])
    },
  },
  created() {
    this.initTeamData()
  },
  methods: {
    hasAtLeastOneRole: guard.hasAtLeastOneRole,
    transformDatetimeToHumanReadablePretty,
    initTeamData() {
      this.loading = true
      this.$store.dispatch('team/fetchTeamById', this.$route.params.id)
        .then(() => {
          this.loading = false
        })
        .catch(err => parseRequestError(err))
    },
    formatDate(date) {
      return transformDatetimeToHumanReadablePretty(date)
    },
    endTeamWork() {
      this.loading = true
      this.$store.dispatch('team/finishTeamWork', this.team.id)
        .then(() => {
          window.toast.notify.success(this.$t('popups.user.init-team.notify.team-work-ended'))
          this.initTeamData()
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.card-justify {
  height: calc(100% - 2rem) !important;
}
</style>
